import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import marketing from '../images/marketing.png'
export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Advertise With Us Page"></meta>
          <title>Marketing Services | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/google-adwords/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="marketing--title">Marketing Services</h1>
      <div className="marketing">
        <div className = "marketing_wrapper">
          <img src={marketing} className="marketing--image" alt="marketing mobile mockups"/>
          <div className = "marketing_grid">
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Social Media Strategy and Advertising</h3>
              <p className="marketing_block--text">We can help you create meaningful customer relationships and drive tangible results by implementing a social media strategy across Facebook, Instagram, Twitter, LinkedIn, and Pinterest.</p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">SEO</h3>
              <p className="marketing_block--text">Let us help you navigate the complex world of SEO. From audits to customized  strategies, we can help move your business up the organic search rankings and provide a clear plan for success.</p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Pay-Per-Click</h3>
              <p className="marketing_block--text">A smart paid search strategy can help your business quickly generate qualified leads. Let us help you implement your PPC campaign by providing a transparent approach with the help of our team of experts.</p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Targeted Emails</h3>
              <p className="marketing_block--text">Emails remain one of the most effective and direct ways to communicate with your ideal customers. Our expert team can help you build out an email strategy that reaches the right people at the right time, including both existing consumers and new prospects.  </p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Programmatic Advertising</h3>
              <p className="marketing_block--text">Our team of digital buyers can help plan and implement a comprehensive campaign that reaches your target consumers wherever and however they spend their time online. Through banner ads, pre-roll, OTT, or native, we will reach your audience in the most impactful way.
</p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Rich Media</h3>
              <p className="marketing_block--text">We can create premium and engaging ad units with great visibility across our websites and apps. </p>
            </div>
            <div className= "marketing_block">
              <h3 className="marketing_block--title">Streaming Audio</h3>
              <p className="marketing_block--text">The power of streaming audio continues to increase. Let us help you plan and buy your streaming audio across various platforms like Spotify, Pandora, Tune In, and more. </p>
            </div>
          </div>
        </div>

        <div className="marketing_banner">
            <h3 className="marketing_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="marketing_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
